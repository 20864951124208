import React, { useEffect } from 'react'


export const Slider = () => {


    useEffect(() => {


        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/rev-script-2.js')

    }, [])

    return (
        <div id="welcome_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header">
            <div id="rev_slider_one" className="rev_slider fullwidthabanner" style={{ display: 'none' }} data-version="5.4.3.1">
                <ul>
                    {/* SLIDE 1 */}
                    <li
                        data-index="rs-902"
                        data-transition="fade"
                        data-slotamount="default"
                        data-hideafterloop={0}
                        data-hideslideonmobile="off"
                        data-easein="default"
                        data-easeout="default"
                        data-masterspeed="default"
                        data-thumb={require('./../../../images/main-slider/slider1/Tuvansa-1.jpg').default}
                        data-rotate={0}
                        data-fstransition="fade"
                        data-fsmasterspeed={300}
                        data-fsslotamount={7}
                        data-saveperformance="off"
                        data-title
                        data-param1
                        data-param2
                        data-param3
                        data-param4
                        data-param5
                        data-param6
                        data-param7
                        data-param8
                        data-param9
                        data-param10
                        data-description
                    >
                        {/* MAIN IMAGE */}
                        <img

                            src={require('./../../../images/main-slider/slider1/Tuvansa-1.jpg').default}
                            alt="f"
                            className="rev-slidebg"
                            data-bgfit="cover"
                            data-bgparallax={10}
                            data-bgposition="center center"
                            data-bgrepeat="no-repeat"
                            data-no-retina
                        />
                        {/* LAYERS */}
                        {/* LAYER NR. 1 [ for overlay ] */}
                        <div
                            className="tp-caption tp-shape tp-shapewrapper "
                            id="slide-902-layer-0"
                            data-x="['center','center','center','center']"
                            data-hoffset="['0','0','0','0']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','0']"
                            data-width="full"
                            data-height="full"
                            data-whitespace="nowrap"
                            data-type="shape"
                            data-basealign="slide"
                            data-responsive_offset="off"
                            data-responsive="off"
                            data-frames="[
                            {&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;}
                        ]"
                            data-textalign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style={{
                                zIndex: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                borderColor: 'rgba(0, 0, 0, 0)',
                                borderWidth: 0
                            }}
                        >
                        </div>
                        {/* LAYER NR. 4 [ for title ] */}
                        <div
                            className="tp-caption   tp-resizeme"
                            id="slide-902-layer-2"
                            data-x="['left','left','left','left']"
                            data-hoffset="['50','130','130','130']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['-120','-120','-120','-120']"
                            // data-fontsize="['72','72','62','52']"
                            data-lineheight="['82','82','72','62']"
                            data-width="['900','900','600']"
                            data-height="['none','none','none','none']"
                            data-whitespace="['normal','normal','normal','normal']"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames="[
                            {&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;mask&quot;:&quot;x:0;y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}
                        ]"
                            data-textalign="['left','left','left','left']"
                            data-paddingtop="[5,5,5,5]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style={{
                                zIndex: 13,
                                whiteSpace: 'normal',
                                fontWeight: 700,
                                color: '#fff',
                                borderWidth: 0,
                                fontFamily: '"Poppins", sans-serif',
                                textTransform: 'uppercase',
                                fontSize: '50px'
                            }}
                        >

                            <div>SOMOS UNA EMPRESA ORGULLOSAMENTE MEXICANA</div>
                        </div>
                        {/* LAYER NR. 5 [ for block] */}
                        {/* LAYER NR. 5 [ for block] */}
                        <div
                            className="tp-caption   tp-resizeme"
                            id="slide-902-layer-4"
                            data-x="['left','left','left','left']"
                            data-hoffset="['50','130','130','130']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','20']"
                            data-fontsize="['20','20','28','26']"
                            data-lineheight="['28','28','48','38']"
                            data-width="['600','600','700','500']"
                            data-height="['none','none','none','none']"
                            data-whitespace="['normal','normal','normal','normal']"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames="[
                            {&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;mask&quot;:&quot;x:0;y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}
                        ]"
                            data-textalign="['left','left','left','left']"
                            data-paddingtop="[5,5,5,5]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style={{
                                zIndex: 13,
                                whiteSpace: 'normal',
                                fontWeight: 500,
                                color: '#fff',
                                borderWidth: 0,
                                fontFamily: '"Poppins", sans-serif'
                            }}
                        >


                            {/* ORGULLOSAMENTE MEXICANA CON
                        MAS DE CINCO DECADAS DE EXPERIENCIA */}
                        </div>
                        {/* LAYER NR. 6 [ for see all service botton ] */}
                        {/* <div className="tp-caption tp-resizeme" id="slide-902-layer-5" data-x="['left','left','left','left']" data-hoffset="['50','130','130','130']" data-y="['middle','middle','middle','middle']" data-voffset="['90','90','90','130']" data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;mask&quot;:&quot;x:0;y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 100, textTransform: 'uppercase', margin: 10 }}>
                                <NavLink to="/about" className="site-button-secondry btn-effect ">More About</NavLink>
                            </div> */}
                        {/* LAYER NR. 6 [ for see all service botton ] */}
                        {/* <div className="tp-caption tp-resizeme" id="slide-902-layer-6" data-x="['left','left','left','left']" data-hoffset="['260','330','330','330']" data-y="['middle','middle','middle','middle']" data-voffset="['90','90','90','130']" data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']" data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on" data-frames="[{&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;mask&quot;:&quot;x:0;y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 100, textTransform: 'uppercase', margin: 10 }}>
                                <NavLink to="/contactus" className="site-button btn-effect">Contact Us</NavLink>
                            </div> */}
                    </li>
                    {/* SLIDE 2*/}
                    <li
                        data-index="rs-903"
                        data-transition="fade"
                        data-slotamount="default"
                        data-hideafterloop={0}
                        data-hideslideonmobile="off"
                        data-easein="default"
                        data-easeout="default"
                        data-masterspeed="default"
                        data-thumb={require('./../../../images/main-slider/slider1/tuberias.jpg').default}
                        data-rotate={0}
                        data-fstransition="fade"
                        data-fsmasterspeed={300}
                        data-fsslotamount={7}
                        data-saveperformance="off"
                        data-title
                        data-param1
                        data-param2
                        data-param3
                        data-param4
                        data-param5
                        data-param6
                        data-param7
                        data-param8
                        data-param9
                        data-param10
                        data-description
                    >


                        {/* MAIN IMAGE */}
                        <img
                            src={require('./../../../images/main-slider/slider1/tuberias.jpg').default}
                            alt=""
                            className="rev-slidebg"
                            data-bgfit="cover"
                            data-bgparallax={10}
                            data-bgposition="center center"
                            data-bgrepeat="no-repeat"
                            data-no-retina
                        />
                        {/* LAYERS */}
                        {/* LAYER NR. 1 [ for overlay ] */}
                        <div
                            className="tp-caption tp-shape tp-shapewrapper "
                            id="slide-903-layer-0"
                            data-x="['center','center','center','center']"
                            data-hoffset="['0','0','0','0']"
                            data-y="['middle','middle','middle','middle']"
                            data-voffset="['0','0','0','0']"
                            data-width="full"
                            data-height="full"
                            data-whitespace="nowrap"
                            data-type="shape"
                            data-basealign="slide"
                            data-responsive_offset="off"
                            data-responsive="off"
                            data-frames="[
                                {&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                                {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;}
                            ]"
                            data-textalign="['left','left','left','left']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"
                            style={{ zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.0)', borderColor: 'rgba(0, 0, 0, 0)', borderWidth: 0 }}
                        >
                        </div>
                        {/* LAYER NR. 4 [ for title ] */}
                        <div
                            className="tp-caption tp-resizeme  "

                            
                           
                            data-x="['left','left','left','left']"
                            data-hoffset="['50','130','130','130']"
                            data-y="['center','center','center','center']"
                            data-voffset="['-120','-120','-120','-120']"
                            data-lineheight="['82','82','72','62']"
                            data-width="['900','900','700']"
                            data-height="['none','none','none','none']"
                            data-whitespace="['normal','normal','normal','normal']"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames="[
                                {&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;mask&quot;:&quot;x:0;y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[5,5,5,5]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 13, whiteSpace: 'normal', fontWeight: 700, color: '#fff', borderWidth: 0, fontFamily: '"Poppins", sans-serif', textTransform: 'uppercase', fontSize: '50px' }}
                        >
                            <div>TENEMOS UNA AMPLIA EXPERIENCIA EN COMERCIALIZACIÓN DE TUBERÍAS, VÁLVULAS Y CONEXIONES PARA USO INDUSTRIAL</div>
                        </div>
                    </li>
                </ul>
                <div className="tp-bannertimer tp-bottom" style={{ visibility: 'hidden !important' }} />
            </div>
        </div>
    )
}
