import p1 from 'images/productos/P-1.jpg';
import p2 from 'images/productos/P-2.jpg';
import p3 from 'images/productos/P-3.jpg';
import p4 from 'images/productos/P-4.jpg';
import p5 from 'images/productos/P-5.jpg';
import p6 from 'images/productos/P-6.jpg';
import p7 from 'images/productos/P-7.jpg';
import p8 from 'images/productos/P-8.jpg';
import p9 from 'images/productos/P-9.jpg';
import p10 from 'images/productos/P-10.jpg';
import p11 from 'images/productos/P-12.jpg';



export const filters = [
    { label: "Tuberias", filter: "tuberias" },
    { label: "Valvulas", filter: "valvulas" },
    { label: "Sistemas", filter: "sistemas" },
    { label: "Conexiones", filter: "conexiones" },
    { label: "Tapas y rejillas", filter: "tapas" },

];



export const productos = [
    {
        id: 1,
        image: p1,
        title: 'Valvulas',
        category: '',
        filter: 'valvulas',
        description: `Válvulas industriales de los tipos Compuerta, Globo, Retención, Bola, Mariposa, Válvulas con extremos ranurados (Victaulic), Duo Check, Diafragma, de Cuchilla, de Pellizco, de Aguja, de Seguridad y Alivio, y otros tipos, en Acero al Carbón, Aceros Aleados, Aceros Inoxidables, Hierro, y otras aleaciones. Las tenemos con extremos bridados, roscados, SW, entre bridas, ranuradas. Además en Plásticos como el PAD o el PVC para fusionarse o pegarse.

        Las principales marcas que ofrecemos son:
        
        WALWORTH
        Válvulas tipo compuerta, globo y retención, de hierro, acero fundido y acero forjado; bola API 6D montada sobre muñón, compuerta de línea API 6D, válvulas macho, válvulas de seguridad y alivio, dúo checks, , en diámetros de ¼” hasta 48”, en clases 125 a 2500 libras/pulgada2, según el tipo.
        
        NEWCON
        Válvulas de compuerta estilo cuchillo para slurry con diámetros entre 2 y 40, sirven para servicio de de apertura y cierre, entregando una vida útil excelente con cierre positivo 100% de slurries abrasivos. Sigue creciendo la cantidad de clientes que eligen NewCon para reemplazar sus válvulas de compuerta de cuchillo, incluso las válvulas de bola, de mariposa y de apriete, para el servicio con apertura total o cierre total Ver mas...
        BRAY
        Válvulas de mariposa de tipos Wafer (Entre bridas), Lug (Orejadas) y Bridadas, de hierro, de acero y acero inoxidable, con asientos de elastómero y combinados metal-elastómero. Válvulas de mariposa de alto rendimiento, de acero al carbón e inoxidable, de doble excentricidad, extremos Wafer, Lug o Bridadas, asientos de teflón desde 2” hasta 56”. Se ofrecen con operadas con palancas, operadores de engranes manuales, actuadores neumáticos y eléctricos, con una gran variedad de accesorios. Válvulas de cuchilla Orbinox.
        
        HAITIMA
        Válvulas de bola en acero al carbón, acero inoxidable, etc. Aplicaciones en agua, aire, vapor, sanitarias, etc., en medidas de ¼” a 12”, en presiones de 800 a 2000 libras/pulgada2 WOG, con apertura manual o con actuadores neumáticos y eléctricos. Válvulas de mariposa tipo Wafer y Lug, en hierro nodular y combinadas con acero inoxidable, asientos de EPDM. Operadas con palanca o con caja de engranes manual, para usos con agua. Válvulas de Compuerta, Globo y Retención de Aceros Inoxidables, roscadas o bridadas.
        
        OTRAS MARCAS
        Manejamos otras marcas como son Worcester, Velan, Genebre, Sharpe, FNW, Nibco, Mueller, Kenedy, Clarkson, Lavsa, SWI, Bonney Forge, Powell, DSI, y otras.`,
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 2,
        image: p2,
        title: 'Tuberias',
        category: '',
        filter: 'tuberias',
        description: `Tubería de acero al carbón sin costura TAMSA / TENARIS en normas ASTM A-106, A-53B, API 5L GR. B, , norma NACE, etc., en diámetros de 1/2” a 24” en sus diferentes grados y espesores.

        Tubería de acero al carbón con costura ASTM A-53 en grados A y B, SAW, DSAW, ERW en diámetros de ½” hasta 60 en diferentes grados y espesores.
        
        Tubería de acero inoxidable con y sin costura norma ASTM A-312, tipos 304L, 316L y otras aleaciones inoxidables; también Tubing inoxidable calibrado en los mismos tipos de aceros.
        
        Tubería de acero al carbón de pared delgada marca SPS Flow / Seah Steel y Villacero. en los tipos Cedula 7, Cédula 10 y BLT, con aprobaciones UL y FM.
        
        Tubería sin costura en aceros aleados, normas ASTM A-333 y en ASTM A-335 en los grados P5, P9, P11, P22, P91 y otros.
        
        Tubería y accesorios de HDPE (High Density Polyethylene ó Polietileno de alta densidad), utilizada en la industria en general, minería y conducción de agua potable y residual, entre otras.
        
        Tubo mecánico también conocido como Barra hueca de acero al carbón, en aleaciones TAMSA tipo TAM-245 y TAM-470 (en medidas de 32mm hasta 508 mm).`,
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 3,
        image: p3,
        title: 'Tuberias especializadas',
        category: '',
        filter: 'tuberias',
        description: `
        Contamos con una amplia variedad de tubería de gran calidad para sus proyectos, tubería con costura HFW recubrimientos FBE, tubería de acero ranurada, sobresaliente, de alta resistencia y baja aleación, tubería XCR-50 lisa con costura HFW. Entre otras
        TUBERÍA DE LÍNEA
        Certificada bajo API-5L Grados B hasta X70.Tubería de acero al carbón con costura HFW en diámetros de 2" a 24".Recubrimientos exteriores FBE, Tricapa de Polietileno (3LPE) y Tricapa de Polipropileno (3LPP).
        TUBERÍA RANURADA
        Tubería de acero ranurada sobresaliente y longitudinal en diámetros de 6" a 20" de alta resistencia para pozos de agua profundos.Amplia variedad de aberturas y áreas de infiltración.TUBERÍA XRC-50.Tubería lisa y ranurada de acero de alta resistencia y baja aleación con costura HFW para pozos con agua potencialmente corrosiva.Mayor vida útil.Altísima resistencia mecánica.
        TUBERÍA ROSCADA CON COPLE
        Tubería de acero roscada para columna de bomba de 4" a 12" de diámetro.Rosca recta y cónica (corta o larga).Minimiza las pérdidas hidráulicas en conducción de fluidos.
        
        `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 4,
        image: p4,
        title: 'Deteccion y alarma',
        category: '',
        filter: 'sistemas',
        description: ` Detección Temprana de Humo, Fuego y Gases, Alarma y Voceo.

        Building Management Systems (BMS) Control Integral para Ediﬁcios Inteligente.  `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 5,
        image: p5,
        title: 'Termoplasticos',
        category: '',
        filter: 'tuberias',
        description: `Sistemas Avanzados que Ofrecen Soluciones Sostenibles e Innovadoras para la Industria, la Ediﬁcación e Infraestructura  `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 6,
        image: p6,
        title: 'PRFV',
        category: '',
        filter: 'tuberias',
        description: `Postes Sólidos y Opalescentes de Resina Termoestable y Fibra de Vidrio, de Alta Resistencia Mecánica, Poco Peso, Libre de Corrosión, Dielectrico y Larga Vida Útil.  `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 7,
        image: p7,
        title: 'Valvulas Newcon',
        category: '',
        filter: 'valvulas',
        description: `Las Válvulas NEWCON se utilizan en aplicaciones de Pulpa Minera (Pulpas Abrasivas) , Slurries en plantas generadoras de potencia.

        Las Válvulas NEWCON son una mejora sustancial con respecto a las actuales tecnologías, tales como el Cuchillo Pasante.  `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 8,
        image: p8,
        title: 'Conexiones',
        category: '',
        filter: 'conexiones',
        description: `Conexión de acero al carbón para soldar, fabricada bajo la norma ASTM A-234 WPB en la gama de ½” a 48”, en cédulas STD y en XS así como en espesores especiales en las marcas TENARIS y otras marcas mundiales.
        Conexión de acero inoxidable norma ASTM A-403 T304L, T316L y otras aleaciones, con o sin costura, cédulas 10s, 40s, 80s y cédulas especiales. 
        Conexión forjada fabricada en acero al carbón en 3000 y 6000 Lb/pg2, ASTM A-105, en acero inoxidable ASTM A-182 F304L, F316L y en otras aleaciones inoxidables.
        Bridas de acero al carbón ASTM A-105, dimensiones ANSI-B 16.5, de ¼” a 48”, clases ANSI 150, 300, 600, 900 y 1500, en tipos deslizable (SO), con cuello (WN), ciega (BLD), roscada (THRD), con caja para soldar (SW) y otros. Bridas de acero inoxidable en los mismos tipos, fabricada bajo la norma ASTM A-182 F304L, F316L y en otras aleaciones inoxidables y diferentes tipos de caras (R.F.,.F.F., R.T.J.).
        Conexiones y accesorios de HDPE (High Density Polyethylene ó Polietileno de alta densidad).`,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 9,
        image: p9,
        title: 'Sistemas de Bombeo',
        category: '',
        filter: 'sistemas',
        description: ` Para: Contraincendio, Agua Helada, Minería, Hidrosanitario, Tratamiento de Agua, Agua Potable y cualquier otra aplicación `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 10,
        image: p10,
        title: 'Sistemas contra Incendios',
        category: '',
        filter: 'sistemas',
        description: ` Conexiones Ranuradas y Accesorios para Sistemas de Aire Acondicionado, Ventilación, Refrigeración y Minería `,
        especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    },
    {
        id: 11,
        image: p11,
        title: 'Tapas y rejillas',
        category: '',
        filter: 'tapas',
        description: `Innovación, Diseño, Fabricación y Distribución de Soluciones de Acceso para Redes de Abastecimiento de Agua, Alcantarillado, Pluviales, Redes de Telecomunicaciones y de Servicios Públicos que Participan de Forma Eficiente en la Recolección, Recuperación, Pre-tratamiento, Contención y Descarga del Agua.  `,
                especifications: [
            {
                title: 'CODO 90',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: 0.18, xs: '0.957', espesorXs: '0.179', pesoXs: '0.22' },
                    { diametro: '1 1/4"', diametroExterior: 1.660, std: 1.380, espesorStd: 0.140, pesoStd: 0.25, xs: 1.278, espesorXs: 0.191, pesoXs: 0.36 },
                    { diametro: '1 1/2"', diametroExterior: 1.900, std: 1.610, espesorStd: 0.145, pesoStd: 0.36, xs: 1.500, espesorXs: 0.200, pesoXs: 0.54 },
                    { diametro: '2"', diametroExterior: 2.375, std: 2.067, espesorStd: 0.154, pesoStd: 0.73, xs: 1.939, espesorXs: 0.218, pesoXs: 0.95 },
                    { diametro: '2 1/2"', diametroExterior: 2.875, std: 2.469, espesorStd: 0.203, pesoStd: 1.45, xs: 2.323, espesorXs: 0.276, pesoXs: 1.72 },
                    { diametro: '3"', diametroExterior: 3.500, std: 3.068, espesorStd: 0.216, pesoStd: 2.18, xs: 2.900, espesorXs: 0.300, pesoXs: 2.86 },
                    { diametro: '4"', diametroExterior: 4.500, std: 4.026, espesorStd: 0.237, pesoStd: 4.04, xs: 3.826, espesorXs: 0.337, pesoXs: 5.67 },
                    { diametro: '5"', diametroExterior: 5.563, std: 5.047, espesorStd: 0.258, pesoStd: 6.85, xs: 4.813, espesorXs: 0.375, pesoXs: 9.62 },
                    { diametro: '6"', diametroExterior: 6.625, std: 6.065, espesorStd: 0.280, pesoStd: 10.89, xs: 5.761, espesorXs: 0.432, pesoXs: 15.60 },
                    { diametro: '8"', diametroExterior: 8.625, std: 7.981, espesorStd: 0.322, pesoStd: 21.70, xs: 7.625, espesorXs: 0.500, pesoXs: 32.27 },
                    { diametro: '10"', diametroExterior: 10.750, std: 10.020, espesorStd: 0.365, pesoStd: 37.86, xs: 9.750, espesorXs: 0.500, pesoXs: 50.39 },
                    { diametro: '12"', diametroExterior: 12.750, std: 12.000, espesorStd: 0.375, pesoStd: 55.84, xs: 11.750, espesorXs: 0.500, pesoXs: 71.73 },
                    { diametro: '14"', diametroExterior: 14.000, std: 13.250, espesorStd: 0.375, pesoStd: 70.37, xs: 13.000, espesorXs: 0.500, pesoXs: 97.25 },
                    { diametro: '16"', diametroExterior: 16.000, std: 15.250, espesorStd: 0.375, pesoStd: 93.52, xs: 15.000, espesorXs: 0.500, pesoXs: 122.58 },
                    { diametro: '18"', diametroExterior: 18.000, std: 17.250, espesorStd: 0.375, pesoStd: 119.00, xs: 17.000, espesorXs: 0.500, pesoXs: 158.00 },
                    { diametro: '20"', diametroExterior: 20.000, std: 19.250, espesorStd: 0.375, pesoStd: 147.00, xs: 19.000, espesorXs: 0.500, pesoXs: 194.00 },
                    { diametro: '24"', diametroExterior: 24.000, std: 23.250, espesorStd: 0.375, pesoStd: 212.00, xs: 23.000, espesorXs: 0.500, pesoXs: 282.00 },
                ]
            },
            {
                title: 'CODO 45',
                data: [
                    { diametro: '1"', diametroExterior: '1.315', std: '1.049', espesorStd: '0.133', pesoStd: '0.10', xs: '0.957', espesorXs: '0.179', pesoXs: '0.13	' },
                    { diametro: '1 1/4"', diametroExterior: '1.660', std: '1.380', espesorStd: '0.140', pesoStd: '0.15', xs: '1.278', espesorXs: '0.191', pesoXs: '0.20	' },
                    { diametro: '1 1/2"', diametroExterior: '1.900', std: '1.610', espesorStd: '0.145', pesoStd: '0.20', xs: '1.500', espesorXs: '0.200', pesoXs: '0.28	' },
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '0.39', xs: '1.939', espesorXs: '0.218', pesoXs: '0.54	' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '0.77', xs: '2.323', espesorXs: '0.276', pesoXs: '0.91	' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '1.13', xs: '2.900', espesorXs: '0.300', pesoXs: '1.50	' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '2.04', xs: '3.826', espesorXs: '0.337', pesoXs: '2.81	' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.470', espesorStd: '0.258', pesoStd: '3.40', xs: '4.813', espesorXs: '0.375', pesoXs: '4.76	' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '5.31', xs: '5.761', espesorXs: '0.432', pesoXs: '7.71	' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '10.57', xs: '7.625', espesorXs: '0.500', pesoXs: '15.56	' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '18.75', xs: '9.750', espesorXs: '0.500', pesoXs: '24.29	' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '27.88', xs: '11.750', espesorXs: '0.500', pesoXs: '35.23	' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '35.46', xs: '13.000', espesorXs: '0.500', pesoXs: '45.40	' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '45.85', xs: '15.000', espesorXs: '0.500', pesoXs: '60.84	' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '60.00', xs: '17.000', espesorXs: '0.500', pesoXs: '79.00	' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '74.00', xs: '19.000', espesorXs: '0.500', pesoXs: '97.00	' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '106.00', xs: '23.000', espesorXs: '0.500', pesoXs: '141.00	' },
                ]
            },
            {
                title: 'CODO 180',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.45', xs: '1.939', espesorXs: '0.218', pesoXs: '1.86' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.77', xs: '2.323', espesorXs: '0.276', pesoXs: '3.49' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '4.26', xs: '2.900', espesorXs: '0.300', pesoXs: '5.62' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.81', xs: '3.364', espesorXs: '0.318', pesoXs: '7.76' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '7.94', xs: '3.826', espesorXs: '0.337', pesoXs: '10.93' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '13.30', xs: '4.813', espesorXs: '0.375', pesoXs: '18.92' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '21.34', xs: '5.761', espesorXs: '0.432', pesoXs: '30.96' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '39.50', xs: '7.625', espesorXs: '0.500', pesoXs: '63.56' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '74.46', xs: '9.750', espesorXs: '0.500', pesoXs: '98.52' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '107.60', xs: '11.750', espesorXs: '0.500', pesoXs: '141.19' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '141.19', xs: '13.000', espesorXs: '0.500', pesoXs: '181.60' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '185.23', xs: '15.000', espesorXs: '0.500', pesoXs: '254.16' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '315.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '388.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '564.00' },
                ]
            },
            {
                title: 'TEE RECTA',
                data: [
                    { diametro: '2"', diametroExterior: '2.375', std: '2.067', espesorStd: '0.154', pesoStd: '1.43', xs: '1.939', espesorXs: '0.218', pesoXs: '1.87' },
                    { diametro: '2 1/2"', diametroExterior: '2.875', std: '2.469', espesorStd: '0.203', pesoStd: '2.68', xs: '2.323', espesorXs: '0.276', pesoXs: '3.08' },
                    { diametro: '3"', diametroExterior: '3.500', std: '3.068', espesorStd: '0.216', pesoStd: '3.82', xs: '2.900', espesorXs: '0.300', pesoXs: '4.50' },
                    { diametro: '3 1/2"', diametroExterior: '4.000', std: '3.548', espesorStd: '0.226', pesoStd: '5.18', xs: '3.364', espesorXs: '0.318', pesoXs: '6.17' },
                    { diametro: '4"', diametroExterior: '4.500', std: '4.026', espesorStd: '0.237', pesoStd: '5.99', xs: '3.826', espesorXs: '0.337', pesoXs: '8.44' },
                    { diametro: '5"', diametroExterior: '5.563', std: '5.047', espesorStd: '0.258', pesoStd: '9.94', xs: '4.813', espesorXs: '0.375', pesoXs: '12.93' },
                    { diametro: '6"', diametroExterior: '6.625', std: '6.065', espesorStd: '0.280', pesoStd: '16.47', xs: '5.761', espesorXs: '0.432', pesoXs: '19.30' },
                    { diametro: '8"', diametroExterior: '8.625', std: '7.981', espesorStd: '0.322', pesoStd: '27.69', xs: '7.625', espesorXs: '0.500', pesoXs: '34.50' },
                    { diametro: '10"', diametroExterior: '10.750', std: '10.020', espesorStd: '0.365', pesoStd: '41.31', xs: '9.750', espesorXs: '0.500', pesoXs: '58.57' },
                    { diametro: '12"', diametroExterior: '12.750', std: '12.000', espesorStd: '0.375', pesoStd: '66.74', xs: '11.750', espesorXs: '0.500', pesoXs: '84.90' },
                    { diametro: '14"', diametroExterior: '14.000', std: '13.250', espesorStd: '0.375', pesoStd: '102.60', xs: '13.000', espesorXs: '0.500', pesoXs: '127.12' },
                    { diametro: '16"', diametroExterior: '16.000', std: '15.250', espesorStd: '0.375', pesoStd: '109.87', xs: '15.000', espesorXs: '0.500', pesoXs: '167.53' },
                    { diametro: '18"', diametroExterior: '18.000', std: '17.250', espesorStd: '0.375', pesoStd: '151.00', xs: '17.000', espesorXs: '0.500', pesoXs: '193.00' },
                    { diametro: '20"', diametroExterior: '20.000', std: '19.250', espesorStd: '0.375', pesoStd: '229.00', xs: '19.000', espesorXs: '0.500', pesoXs: '265.00' },
                    { diametro: '24"', diametroExterior: '24.000', std: '23.250', espesorStd: '0.375', pesoStd: '347.00', xs: '23.000', espesorXs: '0.500', pesoXs: '424.00' },
                ]
            },

        ],
        gallery:[
            {
                image: require('images/productos/P-1.jpg'),
                thumb: require('images/productos/P-1.jpg')
            },
            {
                image: require('images/productos/P-2.jpg'),
                thumb: require('images/productos/P-2.jpg')
            },
            {
                image: require('images/productos/P-3.jpg'),
                thumb: require('images/productos/P-3.jpg')
            },
            {
                image: require('images/productos/P-4.jpg'),
                thumb: require('images/productos/P-4.jpg')
            },
            {
                image: require('images/productos/P-5.jpg'),
                thumb: require('images/productos/P-5.jpg')
            },
            {
                image: require('images/productos/P-6.jpg'),
                thumb: require('images/productos/P-6.jpg')
            },
            {
                image: require('images/productos/P-7.jpg'),
                thumb: require('images/productos/P-7.jpg')
            },
            {
                image: require('images/productos/P-8.jpg'),
                thumb: require('images/productos/P-8.jpg')
            }
        ]
    }
]
