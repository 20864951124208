import React from 'react';
import Components from './components/Components';

const App = () => {
  
  return (
    <div className="App">
      <Components /> 
  
      {/* <ScrolToTop/> */}
      {/* <Loader/>   */}
    </div>
  );
}

export default App;