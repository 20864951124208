import React from 'react'
import { Slider } from 'shared/components/slider/Slider'


export const Home = () => {
  return (
    <>
        <Slider />
        
    
       
    </>
  )
}
